import ComingSoon from "../components/ComingSoon";

const Contact = () => {
    return (
            <div>
                <ComingSoon pageName="Contact Page"/>
            </div>
        );
    };

    export default Contact;